<template>
  <div>
    <h3 class="warning--header">Wait a second!</h3>
    <p>
      Based on {{ dependentNameDisplay() }} age(s), they are a bit too old for this provider.
      Are you sure you want to continue?
    </p>
  </div>
</template>

<script>
export default {
  props: {
    olderChildren: {
      type: Array,
      default() {
        return [];
      },
    },
    bus: {
      type: Object,
      required: true,
    },
    submitStep: {
      type: Function,
      required: true,
    },
  },
  created() {
    this.bus.$on('on-next-step', this.handleNext);
  },
  beforeDestroy() {
    this.bus.$off('on-next-step', this.handleNext);
  },
  methods: {
    handleNext() {
      this.submitStep();
    },
    capitalizeNames(string) {
      if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
      return '';
    },
    dependentNameDisplay() {
      let namesString = '';
      const count = this.olderChildren.length - 1;
      if (this.olderChildren.length >= 1) {
        this.olderChildren.forEach((name, idx) => {
          namesString += idx === count ? `${this.capitalizeNames(name)}'s` : `${this.capitalizeNames(name)}'s and `;
        });
      }
      return namesString;
    },
  },
};
</script>

<style lang="scss" scoped>
  .warning {
    &--blocks {
      max-width: 80px;
      margin: auto;
    }

    &--header {
      padding: 30px 0;
      font-size: 40px;
      font-weight: normal;
      text-align: center;
    }
  }
</style>
