<template>
<div>
  <modal
    :is-open="isOpen"
    @close="closeModal"
    :footerClear="confirmationStep()"
    :dialog-class="confirmationStep() ? 'modal__dialog--for-tour-confirmation' : ''"
  >
    <template #header v-if="showHeader">
      <h6 class="font-body-normal text-color-viridian-5 mb-0">
        {{ `Book a tour with ${facilityName}`.toUpperCase() }}
      </h6>
      <steps v-if="!offerFlow" :current-step="currentStep + 1" :total-steps="totalSteps - 1" />
      <h5 class="font-heading-20 mb-0" :class="{ 'mt-2' : offerFlow }"> {{ stepTitle }} </h5>
    </template>
    <component :is="currentStepComponent" v-bind="currentProperties"></component>
    <template #footer>
      <button-container>
        <system-button
          style-type="clear"
          @click="onPrevStep"
          v-if="(offerFlow || currentStep != 0) && !confirmationStep()"
        >
          {{ prevBtnText }}
        </system-button>

        <system-button
          @click="onNextStep"
          :disabled="disableButton"
          :color="localTourData.cancelChecked ? 'rust' : 'viridian'"
        >
          {{ nextStepBtnText }}
        </system-button>
      </button-container>
    </template>
  </modal>
  <template v-if="loading">
    <loader :loading="loading" />
  </template>
</div>
</template>

<script>
import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import Loader from '@components/loader/loader.vue';
import Modal from '@components/modal/modal.vue';
import Steps from '@components/progress/steps.vue';
import SystemButton from '@components/buttons/system-button.vue';
import ButtonContainer from '@components/layout/button-container.vue';
import dependentsOlderThanFacilityAgeRange from '@mixins/dependentsOlderThanFacilityAgeRange';
import AgeWarning from '@components/legup/age-warning.vue';
import FamilyInfo from '@parent/components/schedule-tour/family-info.vue';
import SelectCareSchedules from '@parent/components/schedule-tour/select-care-schedules.vue';
import DateAndTime from '@parent/components/schedule-tour/date-time.vue';
import Confirmation from '@parent/components/schedule-tour/confirmation.vue';
import { regexPatterns } from '@utils';

export default {
  name: 'legup-tour',
  components: {
    Loader,
    Modal,
    Steps,
    SystemButton,
    ButtonContainer,
    FamilyInfo,
    SelectCareSchedules,
    DateAndTime,
    Confirmation,
  },
  mixins: [dependentsOlderThanFacilityAgeRange],
  props: {
    isOpen: {
      type: Boolean,
      required: false,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    edit: {
      type: Boolean,
    },
    bookedTour: {
      type: Object,
    },
    facilityName: {
      type: String,
      required: true,
    },
    facilityTimezone: {
      type: String,
      required: true,
    },
    facilityPrograms: {
      type: Array,
    },
    legupCenterId: {
      type: Number,
      required: true,
    },
    offerDetails: {
      type: Object,
    },
    fetchOfferData: {
      type: Function,
    },
    seatId: {
      type: Number,
    },
  },
  data() {
    return {
      currentStep: 0,
      dependent: {},
      ageGroupPrograms: [],
      loading: false,
      localTourData: {
        offerDetails: this.offerDetails || {},
        cancelChecked: false,
        dependentId: undefined,
        seatId: this.seatId,
        startCare: '', // ToDo consider this as preferred_date to determine age_group along with DOB
        phoneNumber: '',
        parentComment: '',
        selectedSchedule: [],
        selectedTour: {
          start: '',
          duration: '',
          virtual: '',
          virtualInstructions: '',
        },
      },
      bus: new Vue(),
    };
  },
  mounted() {
    if (!this.childAgeGroupId) {
      this.fetchDependentAgeGroupId();
    }
    this.localTourData.phoneNumber = this.$store.state.user.current.phoneNumber;
  },
  computed: {
    offerFlow() {
      return this.offerDetails;
    },
    currentStepComponent() {
      return this.activeSteps()[this.currentStep].component;
    },
    dependentValues() {
      return Object.values(this.$store.state.dependents.list);
    },
    currentProperties() {
      return {
        tourData: this.edit ? this.bookedTour : this.localTourData,
        olderChildren: this.dependentsOlderThanFacilityAgeRange(this.dependentValues),
        facilityName: this.facilityName,
        timezone: this.facilityTimezone,
        centerId: this.legupCenterId,
        ageGroupPrograms: this.ageGroupPrograms,
        onChange: this.onChange,
        bus: this.bus,
        submitStep: this.submitStep,
      };
    },
    totalSteps() {
      return Object.keys(this.activeSteps()).length;
    },
    stepName() {
      return this.activeSteps()[this.currentStep].stepName;
    },
    showHeader() {
      return !['AgeWarning', 'confirmation'].includes(this.stepName);
    },
    nextStepBtnText() {
      return this.activeSteps()[this.currentStep].nextBtnText;
    },
    prevBtnText() {
      return this.activeSteps()[this.currentStep].prevBtnText;
    },
    stepTitle() {
      return this.activeSteps()[this.currentStep].title;
    },
    disableButton() {
      if (this.loading) return true;

      const activeStep = this.stepName;
      let disable = false;
      if (activeStep === 'familyInfo') {
        disable = !regexPatterns.mobileNumber.regex.test(this.localTourData.phoneNumber)
          || !this.localTourData.phoneNumber || !this.localTourData.dependentId;
      } else if (activeStep === 'careSchedule') {
        disable = !this.ageGroupPrograms.length;
      } else if (activeStep === 'dateTime') {
        disable = !this.localTourData.selectedTour.start.length;
      }
      return disable;
    },
    childAgeGroupId() {
      return this.$store.state.legup.dependentAgeGroup;
    },
  },
  methods: {
    selectedDependent() {
      return this.$store.getters['dependents/dependentById'](this.localTourData.dependentId);
    },
    hasPrograms() {
      if (!this.facilityPrograms) {
        return false;
      }

      return this.facilityPrograms.filter((p) => p.legupAgeGroupId).length > 0;
    },
    activeSteps() {
      if (this.offerFlow) {
        return this.offerTourSteps();
      }
      return this.stepsData().filter((s) => (this.edit ? s.editable : s.display));
    },
    confirmationStep() {
      return this.stepName === 'confirmation';
    },
    onChange(data) {
      const newLocalTourData = cloneDeep(this.localTourData);
      let resetSchedules = false;
      Object.keys(data).forEach((key) => {
        if (key === 'dependentId' && newLocalTourData[key] !== data[key]) {
          resetSchedules = true;
        }
        newLocalTourData[key] = data[key];
      });
      if (resetSchedules) {
        newLocalTourData.selectedSchedule = [];
      }
      this.localTourData = { ...newLocalTourData };
    },
    onPrevStep() {
      const activeStep = this.stepName;
      if (activeStep === 'familyInfo' || activeStep === 'AgeWarning') {
        this.closeModal();
      } else if (this.currentStep > 0) {
        this.currentStep -= 1;
      }
    },
    onNextStep() {
      this.bus.$emit('on-next-step');
    },
    async submitStep() {
      const activeStep = this.activeSteps()[this.currentStep];
      if (activeStep.nextBtnText === 'Submit' && !this.localTourData.cancelChecked) {
        this.loading = true;
        if (this.edit) {
          await this.rescheduleTour();
          this.closeModal();
        } else {
          await this.handleBookTour();
          this.currentStep += 1;
        }
        this.loading = false;
      } else if (this.localTourData.cancelChecked) {
        this.loading = true;
        await this.cancelTour();
        this.loading = false;
        this.closeModal();
        this.currentStep = 0;
        this.localTourData.cancelChecked = false;
      } else if (activeStep.stepName === 'familyInfo' && this.hasPrograms()) {
        if (this.dependent.id !== this.localTourData.dependentId) {
          this.ageGroupPrograms = [];
          this.dependent = this.selectedDependent();
          this.loading = true;
          await this.ageGroupProgramSchedules();
          this.loading = false;
        }
        this.currentStep += 1;
      } else if (activeStep.nextBtnText === 'Okay') {
        this.currentStep = 0;
        this.closeModal();
      } else if (this.currentStep < 3) {
        // increment step
        this.currentStep += 1;
      }
    },

    async fetchDependentAgeGroupId() {
      let { dateOfBirth } = this.dependent;
      if (this.bookedTour && this.bookedTour.child) {
        dateOfBirth = this.bookedTour.child.birthDate;
      }

      if (dateOfBirth) {
        await this.$store.dispatch('legup/dependentAgeGroup', {
          centerId: this.legupCenterId,
          childBirthDate: dateOfBirth,
        });
      }
    },

    async ageGroupProgramSchedules() {
      if (!this.childAgeGroupId) {
        await this.fetchDependentAgeGroupId();
      }

      const programs = await this.$store.dispatch('legup/ageGroupProgramSchedules', { id: this.childAgeGroupId });

      if (programs.length) {
        this.ageGroupPrograms = programs;
      }
    },
    async handleBookTour() {
      if (this.offerFlow) {
        await this.bookOfferTour();
      } else {
        await this.bookRegularTour();
      }
    },
    async bookRegularTour() {
      const {
        id, name, firstName, lastName, email,
      } = this.$store.getters['user/currentParent'];
      const parent = {
        id, name, firstName, lastName, email, phoneNumber: this.localTourData.phoneNumber,
      };
      const {
        firstName: cFirstName, lastName: cLastName, ageMonths, dateOfBirth,
      } = this.dependent;
      const child = {
        firstName: cFirstName, lastName: cLastName, ageMonths, dateOfBirth,
      };
      await this.$store.dispatch('legup/bookTour', {
        centerId: this.legupCenterId,
        parent,
        child,
        tourInfo: this.localTourData,
        noFamilyNotes: false,
      });
      const newTour = await this.$store.getters['legup/upcomingTour'];
      await this.$store.dispatch('user/fetchCurrent');
      this.$emit('tour-booked', { tour: newTour, ageGroupId: this.childAgeGroupId });
    },
    async bookOfferTour() {
      const parent = {
        id: this.offerDetails.parentId,
        firstName: this.offerDetails.parentFirstName,
        lastName: this.offerDetails.parentLastName,
        email: this.offerDetails.parentEmail,
        phoneNumber: this.offerDetails.parentPhoneNumber,
      };
      const child = {
        firstName: this.offerDetails.childFirstName,
        lastName: this.offerDetails.childLastName,
        ageMonths: this.offerDetails.childAgeMonths,
        dateOfBirth: this.offerDetails.childDob,
      };
      await this.$store.dispatch('legup/bookTour', {
        centerId: this.offerDetails.centerId,
        parent,
        child,
        tourInfo: this.localTourData,
        noFamilyNotes: true,
      });
      await this.$store.dispatch('legup/fetchSeatOfferDetails', this.offerDetails.seatId);
      this.$emit('tour-booked', { newSeatOfferState: this.$store.state.legup.seatOfferDetails.offerState });
      this.fetchOfferData(true);
    },
    async rescheduleTour() {
      if (this.bookedTour.startTime === this.localTourData.selectedTour.start) {
        return;
      }
      await this.$store.dispatch('legup/rescheduleTour', {
        tour: this.bookedTour, newDateTime: this.localTourData.selectedTour,
      });
      const newTour = await this.$store.getters['legup/upcomingTour'];
      this.$emit('tour-booked', { tour: newTour, ageGroupId: this.childAgeGroupId });
      if (this.offerFlow) this.fetchOfferData(true);
    },
    async cancelTour() {
      await this.$store.dispatch('legup/cancelTour', { tour: this.bookedTour });
      this.$emit('tour-cancelled');
      if (this.offerFlow) this.fetchOfferData(true);
    },
    createBaseStep(extraProperties = {}) {
      return {
        display: true,
        editable: false,
        prevBtnText: '',
        nextBtnText: 'Okay',
        ...extraProperties,
      };
    },
    offerTourSteps() {
      const dateAndTimeStep = {
        component: DateAndTime,
        title: 'Choose tour date and time',
        stepName: 'dateTime',
        ...this.createBaseStep({
          editable: true,
          prevBtnText: 'Back',
          nextBtnText: 'Submit',
        }),
      };

      const confirmationStep = {
        component: Confirmation,
        stepName: 'confirmation',
        ...this.createBaseStep(),
      };

      return [dateAndTimeStep, confirmationStep];
    },
    stepsData() {
      const ageWarningStep = {
        component: AgeWarning,
        title: 'Wait a second!',
        stepName: 'AgeWarning',
        ...this.createBaseStep({
          display: this.dependentsOlderThanFacilityAgeRange(this.dependentValues).length,
          prevBtnText: 'Go back',
          nextBtnText: 'Continue',
        }),
      };

      const familyInfoStep = {
        component: FamilyInfo,
        title: 'Add family info',
        stepName: 'familyInfo',
        ...this.createBaseStep({
          prevBtnText: 'Cancel',
          nextBtnText: 'Next',
        }),
      };

      const careScheduleStep = {
        component: SelectCareSchedules,
        title: 'Select care schedule',
        stepName: 'careSchedule',
        ...this.createBaseStep({
          display: this.hasPrograms(),
          prevBtnText: 'Back',
          nextBtnText: 'Next',
        }),
      };

      const dateAndTimeStep = {
        component: DateAndTime,
        title: 'Choose tour date and time',
        stepName: 'dateTime',
        ...this.createBaseStep({
          editable: true,
          prevBtnText: 'Back',
          nextBtnText: this.localTourData.cancelChecked ? 'Cancel tour' : 'Submit',
        }),
      };

      const confirmationStep = {
        component: Confirmation,
        stepName: 'confirmation',
        ...this.createBaseStep(),
      };

      return [ageWarningStep, familyInfoStep, careScheduleStep, dateAndTimeStep, confirmationStep];
    },
  },
};
</script>
